// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-markdown-page-index-ts": () => import("/opt/build/repo/src/templates/MarkdownPage/index.ts" /* webpackChunkName: "component---src-templates-markdown-page-index-ts" */),
  "component---src-pages-404-ts": () => import("/opt/build/repo/src/pages/404.ts" /* webpackChunkName: "component---src-pages-404-ts" */),
  "component---src-pages-index-ts": () => import("/opt/build/repo/src/pages/index.ts" /* webpackChunkName: "component---src-pages-index-ts" */),
  "component---src-pages-svg-example-ts": () => import("/opt/build/repo/src/pages/svg-example.ts" /* webpackChunkName: "component---src-pages-svg-example-ts" */)
}

